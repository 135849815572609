export default theme => ({
  blockContainer: {
    position: 'relative',
    marginTop: theme.spacing(2),
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  container: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      position: 'relative',
    },
  },
  alumniContainer: {
    paddingTop: '350px',
    paddingBottom: '350px',
    marginTop: '-290px',
    backgroundColor: theme.palette.blue.main,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0',
      marginTop: '0',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
    },
  },
  title: {
    color: theme.palette.white.main,
    fontSize: '4.3rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  description: {
    color: theme.palette.white.main,
    fontSize: '2.2rem',
    textAlign: 'center',
    maxWidth: '800px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  vimeoContainer: {
    marginTop: '-300px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyItems: 'center',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  rdvFrance: {
    display: 'block',
    margin: '64px auto 64px auto',
    maxWidth: '400px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: '80px',
      objectFit: 'cover',
      display: 'block !important',
      margin: '16px auto 16px auto !important',
    },
  },
  view1: {
    transform: 'translateX(-300px)',
    opacity: 0,
    transition: 'opacity 1.2s ease-in-out, transform 1.2s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateX(-90px)',
    },
  },
  view2: {
    transform: 'scale(0.5)',
    opacity: 0,
    transition: 'opacity 1.2s ease-in-out, transform 1.2s ease-in-out',
  },
  view3: {
    transform: 'translateX(300px)',
    opacity: 0,
    transition: 'opacity 1.2s ease-in-out, transform 1.2s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateX(90px)',
    },
  },
  isVisible: {
    transform: 'initial',
    opacity: 1,
  },
});
