import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { withStyles } from '@mui/styles';
import { Typography, Fab, Button, Box } from '@mui/material';
import Anchor from '../../components/Anchor';
import parse from '../../utils/parse';
import styles from './styles';
import data from '../../utils/data';

import { ReactComponent as Add } from '../../res/add.svg';
import DialogTeam from '../../components/DialogTeam';

class B05Team extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b05team;
    this.common = data.common;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <div className={classes.headerWrapper}>
        <Container maxWidth="xl" className={classes.container}>
          <img src={`${this.imgBase}${this.dataBlock.header}`} alt="" className={classes.header} />
        </Container>
      </div>
    );
  }

  renderTitle() {
    const { classes } = this.props;
    return (
      <div className={classes.titleWrapper}>
        <Container maxWidth="xl" className={classes.container}>
          <Typography variant="h1" className={`${classes.white} ${classes.title}`}>
            {parse(this.dataBlock.title)}
          </Typography>
        </Container>
      </div>
    );
  }

  renderAlumni() {
    const { classes } = this.props;
    return (
      <div className={classes.alumniWrapper}>
        <Container maxWidth="xl" className={`${classes.container} ${classes.alumni}`}>
          <Box className={classes.gridAlumni}>
            {this.dataBlock.alumni.map((alumnus, idx) => (
              <Box key={idx} className={`${classes.alumnus} ${idx === 13 ? classes.last : ''}`}>
                <img src={`${this.imgBase}${alumnus.image}`} alt="" className={classes.image} />
                {idx !== 13 && (
                  <>
                    <Typography className={classes.name} variant="h5">
                      {parse(alumnus.name)}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.country}>
                      {parse(alumnus.country)}
                    </Typography>
                    <Fab color="default" className={classes.btnPlus}>
                      <Add className={classes.addIcon} />
                    </Fab>
                  </>
                )}
                <div className={`${classes.alumniInfo} ${idx === 13 ? classes.lastInfo : ''}`}>
                  <div className={classes.alumniInfoContainer}>
                    <div>
                      <Typography className={classes.nameHover} variant="h5">
                        {parse(alumnus.name)}
                      </Typography>
                      <Typography className={classes.countryHover} variant="subtitle1">
                        {parse(alumnus.country)}
                      </Typography>
                    </div>
                    <Typography variant="body2" sx={{ fontWeight: 700 }} className={classes.textHover}>
                      {parse(alumnus.text)}
                    </Typography>
                    {alumnus.url ? (
                      <div className={classes.discoverContainer}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }} className={classes.discover}>
                          {parse(alumnus.btn)}
                        </Typography>
                        <DialogTeam title={`${parse(alumnus.name)} / ${parse(alumnus.country)}`} url={alumnus.url} />
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </Box>
            ))}
          </Box>
        </Container>
      </div>
    );
  }

  renderButtons() {
    const { classes } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <Button
          sx={{ fontSize: '1em', backgroundColor: theme => theme.palette.blue.medium }}
          variant="contained"
          size="small"
          className={classes.buttonLink}
          href={this.common.comKit}
          target="_blank"
        >
          {this.dataBlock.buttonLink1}
        </Button>
        <Button
          sx={{ fontSize: '1em', backgroundColor: theme => theme.palette.blue.medium }}
          variant="contained"
          size="small"
          className={classes.buttonLink}
          href={this.common.calendar}
          target="_blank"
        >
          {this.dataBlock.buttonLink2}
        </Button>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.blockContainer}>
        <Anchor id="team" />
        {this.renderHeader()}
        {this.renderTitle()}
        {this.renderAlumni()}
        {this.renderButtons()}
      </div>
    );
  }
}

B05Team.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B05Team);
