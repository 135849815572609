export default theme => ({
  blockContainer: {
    position: 'relative',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      paddingBottom: 0,
    },
  },
  container: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      position: 'relative',
    },
  },
  titleWrapper: {
    backgroundColor: theme.palette.blue.main,
    height: 500,
    width: '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      paddingBottom: theme.spacing(8),
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  white: {
    color: theme.palette.blue.contrastText,
  },
  illustrationsWrapper: {
    position: 'relative',
    marginTop: -300,
    '& img': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
    },
  },
  illustration1: {
    position: 'relative',
    zIndex: 1,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  logo: {
    position: 'relative',
    width: '35%',
    left: '5%',
    marginTop: `${theme.spacing(12)} !important`,
    marginBottom: `${theme.spacing(12)} !important`,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
      height: 'auto',
      top: 'auto',
      left: 'auto',
      marginTop: `${theme.spacing(2)} !important`,
      marginBottom: `${theme.spacing(1)} !important`,
    },
  },
  imgLogo: {
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: '80px',
      objectFit: 'cover',
      display: 'block !important',
      margin: 'auto !important',
    },
  },
  illustration2: {
    position: 'absolute',
    zIndex: 0,
    top: 50,
    right: 50,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
      top: 'auto',
      right: 'auto',
    },
  },
});
