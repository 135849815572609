export default theme => ({
  blockContainer: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      paddingBottom: 0,
    },
  },
  container: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      position: 'relative',
    },
  },
  headerWrapper: {
    height: 500,
    width: '100%',
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
      height: 'auto',
    },
  },
  header: {
    width: '100%',
  },
  white: {
    color: theme.palette.blue.contrastText,
  },
  titleWrapper: {
    height: 500,
    width: '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(40),
    backgroundColor: theme.palette.blue.main,
    [theme.breakpoints.down('lg')]: {
      paddingTop: '170px',
      height: 300,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '130px',
      height: 300,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      height: 'auto',
    },
  },
  alumniWrapper: {
    backgroundColor: theme.palette.blue.main,
  },
  gridAlumni: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  alumnus: {
    position: 'relative',
    aspectRatio: 1,
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alumniInfo: {
    position: 'absolute',
    zIndex: 1,
    inset: 0,
    width: 'calc(100%)',
    height: 'calc(100%)',
    backgroundColor: theme.palette.white.main,
    opacity: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    transition: '0.3s ease-in-out',
    '&:hover': {
      opacity: 1,
    },
  },
  alumniInfoContainer: {
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    height: 'inherit',
  },
  name: {
    zIndex: 1,
    transform: 'rotate(-10deg) translate(-32px, 40px)',
    width: 'fit-content',
    textTransform: 'uppercase',
    color: theme.palette.white.main,
    backgroundColor: theme.palette.blue.main,
    padding: '8px',
  },
  country: {
    zIndex: 1,
    transform: 'rotate(-10deg) translate(-32px, 40px)',
    width: 'fit-content',
    textTransform: 'uppercase',
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    padding: '4px 8px',
  },
  textHover: {
    width: 'fit-content',
    color: theme.palette.blue.main,
  },
  nameHover: {
    width: 'fit-content',
    textTransform: 'uppercase',
    color: theme.palette.blue.main,
  },
  countryHover: {
    textTransform: 'uppercase',
    color: theme.palette.red.main,
  },
  discoverContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  discover: {
    textTransform: 'uppercase',
    color: theme.palette.red.main,
  },
  discoverBtn: {
    width: '36px',
    height: '36px',
    cursor: 'pointer',
  },
  btnPlus: {
    zIndex: '1 !important',
    position: 'absolute !important',
    right: '30px',
    bottom: '30px',
    width: '36px !important',
    height: '36px !important',
  },
  addIcon: {
    stroke: `${theme.palette.blue.main} !important`,
    transition: 'stroke 0.3s ease-in-out',
  },
  image: {
    position: 'absolute',
    zIndex: 1,
    inset: 0,
    width: 'calc(100%)',
    height: 'calc(100%)',
    objectFit: 'cover',
  },
  last: {
    aspectRatio: 'initial',
    gridColumn: 'span 3',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 2',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      gridColumn: '',
    },
  },
  lastInfo: {
    '&:hover': {
      opacity: 0,
    },
  },
  buttonContainer: {
    backgroundColor: theme.palette.blue.main,
    paddingTop: '4rem',
    paddingBottom: '4rem',
    display: 'flex',
    alignItems: 'center',
    gap: '4rem',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      gap: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '2rem',
    },
  },
  buttonLink: {
    margin: '0 !important',
    width: 'fit-content !important',
    backgroundColor: `${theme.palette.red.main} !important`,
    borderRadius: '2rem !important',
    height: 'fit-content',
  },
});
