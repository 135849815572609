import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Container, Typography } from '@mui/material';
import { InView } from 'react-intersection-observer';
import Anchor from '../../components/Anchor';

import styles from './styles';
import data from '../../utils/data';

class B02Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b02gallery;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
    this.state = {
      inView1: false,
      inView2: false,
      inView3: false,
      inView4: false,
    };
    this.onView = this.onView.bind(this);
  }

  onView(name, inView) {
    this.setState(prevState => ({ [name]: inView || prevState[name] }));
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <div>
        <Typography mb={4} variant="h1" className={classes.title}>
          {this.dataBlock.title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {this.dataBlock.description}
        </Typography>
      </div>
    );
  }

  renderGallery() {
    const { classes } = this.props;
    return (
      <div className={classes.galleryDuo}>
        <img alt="" className={classes.galleryDuoElement} src={`${this.imgBase}${this.dataBlock.img1}`} />
        <img alt="" className={classes.galleryDuoElement} src={`${this.imgBase}${this.dataBlock.img2}`} />
      </div>
    );
  }

  renderPosters() {
    const { classes } = this.props;
    const { inView1, inView2, inView3, inView4 } = this.state;

    return (
      <div className={classes.posterContainer}>
        <InView
          onChange={inView => this.onView('inView1', inView)}
          className={inView1 ? `${classes.view1} ${classes.isVisibleTop}` : classes.view1}
        >
          <img alt="" className={classes.posterElement} src={`${this.imgBase}${this.dataBlock.poster1}`} />
        </InView>
        <InView
          onChange={inView => this.onView('inView2', inView)}
          className={inView2 ? `${classes.view2} ${classes.isVisibleBottom}` : classes.view2}
        >
          <img alt="" className={classes.posterElement} src={`${this.imgBase}${this.dataBlock.poster2}`} />
        </InView>
        <InView
          onChange={inView => this.onView('inView3', inView)}
          className={inView3 ? `${classes.view3} ${classes.isVisibleTop}` : classes.view3}
        >
          <img alt="" className={classes.posterElement} src={`${this.imgBase}${this.dataBlock.poster3}`} />
        </InView>
        <InView
          onChange={inView => this.onView('inView4', inView)}
          className={inView4 ? `${classes.view4} ${classes.isVisibleBottom}` : classes.view4}
        >
          <img alt="" className={classes.posterElement} src={`${this.imgBase}${this.dataBlock.poster4}`} />
        </InView>
      </div>
    );
  }

  renderContent() {
    const { classes } = this.props;
    return (
      <Container maxWidth="xl" className={classes.container}>
        {this.renderHeader()}
        {this.renderGallery()}
        {this.renderPosters()}
      </Container>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.blockContainer}>
        <Anchor id="gallery" />
        {this.renderContent()}
      </div>
    );
  }
}

B02Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B02Gallery);
