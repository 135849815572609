import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { withStyles } from '@mui/styles';
import { Typography, Stack } from '@mui/material';
import Anchor from '../../components/Anchor';

import parse from '../../utils/parse';
import styles from './styles';
import data from '../../utils/data';

class B01Season extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b01season;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderTitle() {
    const { classes } = this.props;
    return (
      <div className={classes.titleWrapper}>
        <Container maxWidth="xl" className={classes.container}>
          <Typography variant="h1" className={`${classes.white} ${classes.title}`}>
            {parse(this.dataBlock.title)}
          </Typography>
        </Container>
      </div>
    );
  }

  renderIlllustrations() {
    const { classes } = this.props;
    return (
      <Stack className={classes.illustrationsWrapper} direction="column" spacing={2}>
        <div className={classes.illustration1}>
          <img src={`${this.imgBase}${this.dataBlock.illustration1}`} alt="" className={classes.imgIllustration1} />
        </div>
        <div className={classes.illustration2}>
          <img src={`${this.imgBase}${this.dataBlock.illustration2}`} alt="" className={classes.imgIllustration2} />
        </div>
        <div className={classes.logo}>
          <img src={`${this.imgBase}${this.dataBlock.logo}`} alt="" className={classes.imgLogo} />
        </div>
        <div className={classes.illustration3}>
          <img src={`${this.imgBase}${this.dataBlock.illustration3}`} alt="" className={classes.imgIllustration3} />
        </div>
      </Stack>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.blockContainer}>
        <Anchor id="season" />
        {this.renderTitle()}
        <Container maxWidth="xl" className={classes.container}>
          {this.renderIlllustrations()}
        </Container>
      </div>
    );
  }
}

B01Season.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B01Season);
