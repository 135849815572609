import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Box, Container, Typography } from '@mui/material';
import { InView } from 'react-intersection-observer';
import Slider from '../../components/Slider';
import Anchor from '../../components/Anchor';
import parse from '../../utils/parse';

import styles from './styles';
import data from '../../utils/data';
import RdvFrance from '../../res/rdvFrance.gif';
import VimeoPlay from '../../components/VimeoPlay';

class B04Alumni extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b03alumni;
    // this.imgBase = `${this.props.baseUrl}/blocks/`;
    this.handleIsPlayed = this.handleIsPlayed.bind(this);
    this.state = {
      isPlayed: [false, true, false],
      inView1: false,
      inView2: false,
      inView3: false,
    };
    this.onView = this.onView.bind(this);
  }

  onView(name, inView) {
    this.setState(prevState => ({ [name]: inView || prevState[name] }));
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <div className={classes.headerContainer}>
        <Typography variant="h1" className={classes.title}>
          {parse(this.dataBlock.title)}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {parse(this.dataBlock.description)}
        </Typography>
      </div>
    );
  }

  renderSlider() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <Slider sliderData={this.dataBlock.slider} imgPath="blocks" imgFormat="imgCover"></Slider>
      </div>
    );
  }

  handleIsPlayed(position) {
    const { isPlayed } = this.state;
    const newState = isPlayed.map((item, index) => (index === position ? !item : false));
    this.setState({ isPlayed: newState });
  }

  renderContent() {
    const { classes } = this.props;
    const { isPlayed, inView1, inView2, inView3 } = this.state;
    return (
      <Box>
        <Box className={classes.alumniContainer}>{this.renderHeader()}</Box>
        <Container maxWidth="lg" className={classes.container}>
          <Box className={classes.vimeoContainer}>
            <InView
              onChange={inView => this.onView('inView1', inView)}
              className={inView1 ? `${classes.view1} ${classes.isVisible}` : classes.view1}
            >
              <VimeoPlay
                handleIsPlayed={this.handleIsPlayed}
                position={0}
                isPlayed={isPlayed[0]}
                vimeoUrl={this.dataBlock.vimeo1.url}
              />
            </InView>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <InView
                onChange={inView => this.onView('inView2', inView)}
                className={inView2 ? `${classes.view2} ${classes.isVisible}` : classes.view2}
              >
                <VimeoPlay
                  handleIsPlayed={this.handleIsPlayed}
                  position={1}
                  isPlayed={isPlayed[1]}
                  muted
                  vimeoUrl={this.dataBlock.vimeo2.url}
                />
              </InView>
            </Box>
            <InView
              onChange={inView => this.onView('inView3', inView)}
              className={inView3 ? `${classes.view3} ${classes.isVisible}` : classes.view3}
            >
              <VimeoPlay
                handleIsPlayed={this.handleIsPlayed}
                position={2}
                isPlayed={isPlayed[2]}
                vimeoUrl={this.dataBlock.vimeo3.url}
              />
            </InView>
          </Box>
        </Container>
        <img src={RdvFrance} alt="" className={classes.rdvFrance} />
      </Box>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.blockContainer}>
        <Anchor id="alumni" />
        <Container maxWidth="xl" className={classes.container}>
          {this.renderSlider()}
        </Container>
        {this.renderContent()}
      </div>
    );
  }
}

B04Alumni.propTypes = {
  classes: PropTypes.object.isRequired,
  // baseUrl: PropTypes.string,
};

export default withStyles(styles)(B04Alumni);
