export default theme => ({
  appbar: {
    position: 'relative',
    zIndex: 101,
    backgroundColor: `${theme.palette.white.main} !important`,
  },
  headerWrapper: {
    position: 'relative',
    maxWidth: 1400,
    padding: '0 24px',
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
    },
  },
  toolbar: {
    height: 91,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  buttonText: {
    fontSize: '1em',
    textDecoration: 'underline !important',
    textTransform: 'initial !important',
    fontWeight: 'lighter !important',
  },
  buttonPDF: {
    backgroundColor: `${theme.palette.red.main} !important`,
    borderRadius: '2rem !important',
    marginLeft: `${theme.spacing(4)} !important`,
    height: 'fit-content',
    width: 'fit-content',
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.6rem !important',
      width: '125px !important',
      '& *': {
        display: 'none !important',
      },
    },
  },
  logo: {
    height: '60px',
    // width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      height: '30px',
    },
  },
  logoSmall: {
    height: '20px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20px',
  },
});
