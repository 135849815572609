export default theme => ({
  blockContainer: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      paddingBottom: 0,
    },
  },
  container: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      overflow: 'hidden',
      backgroundColor: theme.palette.white.main,
      position: 'relative',
      marginTop: 0,
    },
  },
  videoContainer: {
    '& > iframe': {
      aspectRatio: '16/9',
      width: '100% !important',
      height: 'auto !important',
      maxHeight: '85vh',
    },
  },
});
