export default {
  common: {
    mainTitle: 'Campus France Campagne 2022-2023',
    headerChiffresCle: 'Chiffres-clés',
    headerReseaux: 'Réseaux sociaux 2021',
    headerFb: 'https://www.facebook.com/CampusFranceParis/',
    headerTw: 'https://twitter.com/campusfrance?lang=fr',
    headerLi: 'https://fr.linkedin.com/company/campusfrance',
    headerYt: 'https://www.youtube.com/user/ParisCampusFrance',
    headerInsta: 'https://www.instagram.com/campus_france/?hl=fr',
    headerDownload: 'Télécharger le kit com',
    footerReturnLink: 'https://www.campusfrance.org/fr',
    footerCredit: 'Crédits',
    backToSite: 'Retour au site',
    comKit: 'https://sharing.oodrive.com/share-access/sharings/s5E-8vOM.8lLFl60-#/filer/share-access',
    calendar: 'https://docs.google.com/spreadsheets/d/1PAIBFBY0Cw6W5o-mO3fsCOEuTqUKx_wzYjlyCaWs-v4/edit#gid=460502885',
  },
  blocks: {
    b00intro: {
      vimeo: 'https://vimeo.com/801253350/3b0b12ce5f',
    },
    b01season: {
      title: '2023<span style="font-family: Arial;">,</span> la nouvelle saison<br> de Rendez-vous en France',
      logo: 'b01-logo.gif',
      illustration1: 'b01-illustration1.jpg',
      illustration2: 'b01-illustration2.jpg',
      illustration3: 'b01-illustration3.jpg',
    },
    b02gallery: {
      title: 'Une photothèque riche',
      description:
        'Environ 40 nouveaux visuels qui misent sur l’effet de groupe et mettent également en scène les alumni dans un environnement académique et de recherche, afin de s’accorder avec le nouveau claim. Cette année, les visuels et les vidéos montrent les alumni à Lyon, Strasbourg, Toulouse et Saint-Étienne afin d’illustrer la diversité et la richesse des paysages français.',
      img1: 'b02-g1.jpg',
      img2: 'b02-g2.jpg',
      poster1: 'b02-poster01.jpg',
      poster2: 'b02-poster02.jpg',
      poster3: 'b02-poster03.jpg',
      poster4: 'b02-poster04.jpg',
    },
    b03alumni: {
      title: '5 nouveaux alumni',
      description:
        'Angelo (Italie), Moza (Qatar), Claudia (Gabon) et Carlsson (Malaisie), Alicia (Espagne). Ces nouveaux ambassadeurs incarnent la campagne aux côtés des 8 alumni recrutés en 2021 (Argentine, Australie, Colombie, Cambodge, Côte d’Ivoire, Egypte, Kazakhstan et Kenya).',
      slider: [
        { img: 'b03-slider1.jpg' },
        { img: 'b03-slider2.jpg' },
        { img: 'b03-slider3.jpg' },
        { img: 'b03-slider4.jpg' },
        { img: 'b03-slider5.jpg' },
        { img: 'b03-slider6.jpg' },
        { img: 'b03-slider7.jpg' },
      ],
      vimeo1: { url: 'https://vimeo.com/805183709' },
      vimeo2: { url: 'https://vimeo.com/803810346/db1828c2e7' },
      vimeo3: { url: 'https://vimeo.com/805183756' },
    },
    b04claim: {
      title: 'Skills & friends for life*<br> Un nouveau claim complémentaire,',
      description:
        'qui prolonge et précise l’invitation “Rendez-vous en France”.<br> Venir étudier en France, c’est aller à la rencontre des autres et de soi-même :<br> acquérir des savoir-faire pour s’accomplir dans sa vie professionnelle, mais aussi se faire des amis issus de tous les horizons, et conserver un lien affectif avec la France.',
      subdescription: '*Adaptation française : “A la rencontre des autres et de vous-même”',
      buttonText: 'Pour en savoir plus sur la campagne 2021',
      kit: 'Dans le <br>kit com<span style="font-family: Arial;">,</span> vous trouverez :',
      prints: [
        'formats web et réseaux sociaux<br> (vidéos, reels, stories);',
        'template sans texte et fichiers<br> sources pour adaptations locales;',
        'formats print;',
        'versions française, anglaise,<br> espagnole pour tous les formats.',
      ],
      img1: 'b04-main.jpg',
      img2: 'b04-pc.jpg',
      img3: 'b04-posters.jpg',
    },
    b05team: {
      header: 'b05-header.jpg',
      title: 'Nos alumni',
      buttonLink1: 'Télécharger le kit com',
      buttonLink2: 'Télécharger le calendrier éditorial',
      alumni: [
        {
          image: 'b05-claudia.jpg',
          name: 'claudia',
          country: 'Gabon',
          text: 'Passionnée par la finance, elle étudie l’audit dans une école de commerce à Lyon.',
          url: 'https://vimeo.com/801278341/9424c63ccd',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-carlsson.jpg',
          name: 'carlsson',
          country: 'Malaisie',
          text: 'Boursier du programme Malaisie, il est diplômé d’un master en génie mécanique à Toulouse.',
          url: 'https://vimeo.com/801273978/65972098ef ',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-angelo.jpg',
          name: 'angelo',
          country: 'Italie',
          text: 'Après un échange académique à Paris dans le cadre de son Master of European Design, il travaille aujourd’hui comme designer et photographe.',
          url: 'https://vimeo.com/801269217/9e5aaf5ea3 ',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-alyssa.jpg',
          name: 'alyssa',
          country: 'Australie',
          text: 'Après un échange académique en France, elle développe la communication internationale d’une université parisienne.',
          url: 'https://vimeo.com/805185710',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-andre.jpg',
          name: 'andré',
          country: 'Kazakhstan',
          text: 'Fondateur du festival de cinéma kazakh en France, il est également consultant spécialisé en informatique.',
          url: 'https://vimeo.com/805187744',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-sandrine.jpg',
          name: 'sandrine',
          country: 'Egypte',
          text: 'Après un master en droit des affaires internationales, elle est juriste d’entreprise.',
          url: 'https://vimeo.com/805189283',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-valentina.jpg',
          name: 'valentina',
          country: 'Argentine',
          text: 'Lauréate de la bourse Eiffel, elle est responsable du développement international d’une école d’ingénieur française.',
          url: 'https://vimeo.com/805186920',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-alicia.jpg',
          name: 'alicia',
          country: 'Espagne',
          text: 'Diplômée d’un master 2 d’interprétation de conférence à Strasbourg, elle souhaite travailler comme interprète dans une instance européenne.',
          url: 'https://vimeo.com/801267337/4faa7c36d2',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-moza.jpg',
          name: 'moza',
          country: 'Qatar',
          text: 'Diplômée d’un Master of Science à Doha, elle poursuit un doctorat dans le domaine de l’agro-alimentaire.',
          url: '',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-lucas.jpg',
          name: 'lucas',
          country: 'Kenya',
          text: 'Passionné par les liens culturels entre le Kenya et la France, il prépare un doctorat en histoire de l’art et archéologie.',
          url: '',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-luis.jpg',
          name: 'luis',
          country: 'Colombie',
          text: 'Après des études d’ingénieur à Metz, Luis est expert en qualité dans le secteur pharmaceutique. ',
          url: '',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-tchailga.jpg',
          name: 'tchailga',
          country: "Côte d'ivoire",
          text: 'Après un master en économie du développement et de l’action humanitaire, elle tra vaille dans la prévoyance santé.',
          url: '',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-sothearath.jpg',
          name: 'sothearath',
          country: 'Cambodge',
          text: 'Jeune docteur en économie, ses travaux portent sur les monnaies locales.',
          url: '',
          btn: 'Découvrez son portrait',
        },
        {
          image: 'b05-placeholder.jpg',
          name: '',
          country: '',
          text: '',
          btn: '',
        },
      ],
    },
    popups: {},
  },
};
