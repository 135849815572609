import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
// import PdfLoader from '../../utils/PdfLoader';
import Logo from '../../res/logo-campus-france.png';
import Welcome from '../../res/bienvenue.png';
import PictoTw from '../../res/picto_twitter_1.svg';
import PictoLi from '../../res/picto_linkedin_1.svg';
import PictoYt from '../../res/picto_youtube_1.svg';
import PictoFb from '../../res/picto_facebook_1.svg';
import PictoInsta from '../../res/picto_instagram_1.svg';
import { ReactComponent as LogoFr } from '../../res/logo-republique-francaise.svg';
import { ReactComponent as DownloadIcon } from '../../res/download-icon.svg';

import data from '../../utils/data';

import styles from './styles';

class Header extends React.Component {
  render() {
    const { classes } = this.props;
    const { common } = data;
    return (
      <AppBar elevation={0} position="relative" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={{ xs: 1, md: 2 }}
            className={classes.headerWrapper}
          >
            <Stack direction="row" sx={{ flex: 1 }} gap="10px" justifyContent="flex-start">
              <LogoFr className={classes.logo} style={{ width: 70 }} />
              <img src={Logo} className={classes.logo} alt="" />
            </Stack>
            <Stack direction="row" sx={{ flex: { xs: 0, md: 1 } }} justifyContent="center">
              <img src={Welcome} className={classes.logo} alt="" />
            </Stack>
            <Stack
              direction="row"
              sx={{ whiteSpace: 'nowrap', flex: 1, display: { xs: 'none', md: 'block' } }}
              justifyContent={{ lg: 'center', sm: 'flex-end' }}
            >
              <Box>
                <a href={common.headerTw} target="_blank">
                  <img src={PictoTw} className={classes.logoSmall} alt="" />
                </a>
                <a href={common.headerLi} target="_blank">
                  <img src={PictoLi} className={classes.logoSmall} alt="" />
                </a>
                <a href={common.headerYt} target="_blank">
                  <img src={PictoYt} className={classes.logoSmall} alt="" />
                </a>
                <a href={common.headerFb} target="_blank">
                  <img src={PictoFb} className={classes.logoSmall} alt="" />
                </a>
                <a href={common.headerInsta} target="_blank">
                  <img src={PictoInsta} className={classes.logoSmall} alt="" />
                </a>
                <Button
                  // onClick={PdfLoader(common.pdf)}
                  sx={{ fontSize: '1rem', backgroundColor: theme => theme.palette.blue.medium }}
                  variant="contained"
                  color="pink"
                  className={classes.buttonPDF}
                  startIcon={<DownloadIcon />}
                  href={common.comKit}
                  target="_blank"
                >
                  {common.headerDownload}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
