export default theme => ({
  blockContainer: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  container: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      position: 'relative',
    },
  },
  title: {
    color: theme.palette.blue.main,
    fontSize: '4.3rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  description: {
    color: theme.palette.blue.main,
    fontSize: '2.2rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  galleryDuo: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(1),
    marginTop: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  galleryDuoElement: {
    width: '100%',
    objectFit: 'cover',
  },
  posterContainer: {
    backgroundColor: theme.palette.blue.main,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(4),
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  posterElement: {
    width: '100%',
    objectFit: 'cover',
  },
  view1: {
    transform: 'translateY(100px)',
    opacity: 0,
    transition: 'opacity 1.5s ease-in-out, transform 1.5s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(50px)',
    },
  },
  view2: {
    transform: 'translateY(-100px)',
    opacity: 0,
    transition: 'opacity 1.5s ease-in-out, transform 1.5s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(-50px)',
    },
  },
  view3: {
    transform: 'translateY(100px)',
    opacity: 0,
    transition: 'opacity 1.5s ease-in-out, transform 1.5s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(50px)',
    },
  },
  view4: {
    transform: 'translateY(-100px)',
    opacity: 0,
    transition: 'opacity 1.5s ease-in-out, transform 1.5s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(50px)',
    },
  },
  isVisibleTop: {
    transform: 'translateY(-16px)',
    opacity: 1,
  },
  isVisibleBottom: {
    transform: 'translateY(16px)',
    opacity: 1,
  },
});
