import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, Typography } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import Player from '@vimeo/player';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styles from './styles';
import PlayBtn from '../../res/play-filled-red.svg';
import parse from '../../utils/parse';

class DialogTeam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vimeoId: DialogTeam.getUniqueId(),
      open: false,
      isReady: false,
    };
    this.onReady = this.onReady.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.nodeRef = React.createRef();
  }

  componentDidMount() {
    if (this.state.open) {
      this.delayInitPlayer();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open !== this.state.open && this.state.open) {
      this.delayInitPlayer();
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  initPlayer() {
    const { url } = this.props;
    const { vimeoId } = this.state;
    this.player = new Player(vimeoId, {
      id: url,
      width: 580,
      autoplay: false,
      controls: true,
      loop: true,
      quality: '1024p',
      title: false,
      byline: false,
    });
    this.player.ready().then(this.onReady);
  }

  delayInitPlayer() {
    if (this.nodeRef?.current) {
      this.initPlayer();
    } else {
      this.timeoutId = setTimeout(() => this.delayInitPlayer(), 50);
    }
  }

  onReady() {
    this.setState({ isReady: true });
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false, isReady: false });
  }

  render() {
    const { open, vimeoId, isReady } = this.state;
    const { title, classes } = this.props;
    return (
      <div>
        <button type="button" className={classes.btnClick} onClick={this.handleClickOpen}>
          <img src={PlayBtn} alt="Ouvrir la popup vidéo" className={classes.discoverBtn} />
        </button>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={this.handleClose}
          aria-labelledby={`dialogTitle-${vimeoId}`}
          open={open}
          className={classes.dialogContainer}
        >
          <Box>
            <Typography variant="h6" className={classes.dialogTitle}>
              {parse(title)}
            </Typography>
            {open ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Box>
          <MuiDialogContent>
            <Box id={`dialogTitle-${vimeoId}`} onClose={this.handleClose} />
            <Box className={classes.videoContainer} ref={this.nodeRef} id={vimeoId} {...isReady} />
          </MuiDialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogTeam.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
};

DialogTeam.lastIncr = 0;

DialogTeam.getUniqueId = () => {
  DialogTeam.lastIncr++;
  return `vimeo-team-${DialogTeam.lastIncr}`;
};

export default withStyles(styles)(DialogTeam);
