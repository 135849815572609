export default theme => ({
  container: {
    padding: '0 !important',
    height: '65vh',
    maxHeight: '700px',
    maxWidth: 'none !important',
    [theme.breakpoints.down('sm')]: {
      height: '300px',
      width: '100vw',
    },
  },
  points: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10,
    pointerEvents: 'all',
    [theme.breakpoints.down('sm')]: {
      right: 'auto',
      left: '50%',
      transform: 'translateX(-50%)',
      whiteSpace: 'nowrap',
    },
  },
  point: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    height: 15,
    width: 15,
    borderRadius: 15,
    cursor: 'pointer',
  },
  pointsblue: {
    border: `solid 1px ${theme.palette.blue.main}`,
  },
  pointswhite: {
    border: `solid 1px ${theme.palette.white.main}`,
  },
  activePointsblue: {
    cursor: 'initial',
    backgroundColor: theme.palette.blue.main,
  },
  activePointswhite: {
    cursor: 'initial',
    backgroundColor: theme.palette.white.main,
  },
  arrow: {
    position: 'absolute !important',
    top: '50%',
    width: 50,
    height: 50,
    transform: 'translateY(-50%)',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(calc(-50% + 44px))',
    },
  },
  arrowsblue: {
    color: `${theme.palette.blue.main} !important`,
    backgroundColor: 'rgba(255, 255, 255, 0.4) !important',
  },
  arrowswhite: {
    color: theme.palette.white.main,
  },
  leftArrow: {
    left: theme.spacing(3),
    '& path': {
      transform: 'translateX(5px)',
    },
  },
  rightArrow: {
    right: theme.spacing(3),
  },
  slideInfo: {
    position: 'relative',
    display: 'block',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  itemActive: {
    pointerEvents: 'all',
    zIndex: 2,
  },
  blue: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.white.main,
  },
  white: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.blue.main,
  },
  imgInner: {
    flex: '13 1 0',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 0',
    },
  },
  imgItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    zIndex: 1,
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'top left',
  },
  imgContain: {
    objectFit: 'contain',
    objectPosition: 'right center',
    [theme.breakpoints.down('sm')]: {
      objectPosition: 'center bottom',
    },
  },
});
