export default theme => ({
  toolbar: {
    height: 203,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  buttonText: {
    fontSize: '1em',
    textDecoration: 'underline !important',
    textTransform: 'initial !important',
    fontWeight: 'lighter !important',
  },
  logo: {
    height: '60px',
    [theme.breakpoints.down('md')]: {
      height: '30px',
    },
  },
  logoSmall: {
    height: '20px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20px',
  },
  buttonLink: {
    margin: 'auto !important',
    width: 'fit-content !important',
    backgroundColor: `${theme.palette.blue.main} !important`,
    borderRadius: '2rem !important',
    height: 'fit-content',
    paddingLeft: '16px !important',
    paddingRight: '16px !important',
  },
});
