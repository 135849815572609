import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import Player from '@vimeo/player';
import Anchor from '../../components/Anchor';

import styles from './styles';
import data from '../../utils/data';

class B00Intro extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b00intro;
  }

  componentDidMount() {
    this.initPlayer();
  }

  initPlayer() {
    this.player = new Player('main-video', {
      url: this.dataBlock.vimeo,
      width: 1000,
      autoplay: true,
      muted: true,
      controls: true,
      loop: true,
      quality: '1024p',
      title: false,
      byline: false,
      background: true,
      autopause: false,
    });
    this.player.ready().then();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.blockContainer}>
        <Anchor id="intro" />
        <Container maxWidth="xl" className={classes.container}>
          <Box id="main-video" className={classes.videoContainer} />
        </Container>
      </div>
    );
  }
}

B00Intro.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(B00Intro);
