export default theme => ({
  container: {
    padding: '0 !important',
    maxWidth: 'none !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  btnClick: {
    margin: '0',
    padding: '0',
    border: 'none',
    backgroundColor: 'transparent',
  },
  discoverBtn: {
    width: '36px',
    height: '36px',
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute !important',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: `${theme.palette.white.main} !important`,
  },
  dialogTitle: {
    width: 'fit-content',
    textTransform: 'uppercase',
    margin: '16px auto auto auto !important',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
  },
  dialogContainer: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.blue.main,
      padding: `${theme.spacing(2)} 0 !important`,
    },
  },
  videoContainer: {
    '& > iframe': {
      display: 'block',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
      },
    },
  },
});
