import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

import Logo from '../../res/logo-campus-france.png';
import { ReactComponent as LogoFr } from '../../res/logo-republique-francaise.svg';

import data from '../../utils/data';
import PictoTw from '../../res/picto_twitter_1.svg';
import PictoLi from '../../res/picto_linkedin_1.svg';
import PictoYt from '../../res/picto_youtube_1.svg';
import PictoFb from '../../res/picto_facebook_1.svg';
import PictoInsta from '../../res/picto_instagram_1.svg';
import styles from './styles';

class Footer extends React.Component {
  render() {
    const { classes, onPopup } = this.props;
    const { common } = data;
    return (
      <Box>
        <AppBar sx={{ color: 'blue.main', backgroundColor: 'white.main' }} position="relative">
          <Toolbar className={classes.toolbar}>
            <Grid container>
              <Grid item xs sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Stack direction="row" gap="64px" justifyContent="center">
                  <LogoFr className={classes.logo} style={{ width: 70 }} />
                  <img src={Logo} className={classes.logo} alt="" />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack direction="column" alignContent="center" alignItems="center">
                  <Box sx={{ marginBottom: '16px' }}>
                    <a href={common.headerTw} target="_blank">
                      <img src={PictoTw} className={classes.logoSmall} alt="" />
                    </a>
                    <a href={common.headerLi} target="_blank">
                      <img src={PictoLi} className={classes.logoSmall} alt="" />
                    </a>
                    <a href={common.headerYt} target="_blank">
                      <img src={PictoYt} className={classes.logoSmall} alt="" />
                    </a>
                    <a href={common.headerFb} target="_blank">
                      <img src={PictoFb} className={classes.logoSmall} alt="" />
                    </a>
                    <a href={common.headerInsta} target="_blank">
                      <img src={PictoInsta} className={classes.logoSmall} alt="" />
                    </a>
                  </Box>
                  <Button
                    size="small"
                    sx={{ fontSize: '0.8rem', backgroundColor: theme => theme.palette.blue.main }}
                    variant="contained"
                    className={classes.buttonLink}
                    href={common.footerReturnLink}
                    target="_blank"
                  >
                    {common.backToSite}
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Stack direction="column" alignContent="center" alignItems="center" spacing={2}>
                  <div
                    className={classes.buttonText}
                    variant="text"
                    color="blue"
                    onClick={() => onPopup('credits', true)}
                  >
                    {common.footerCredit}
                  </div>
                  <Typography variant="subtitle2">Design LUCIOLE 2023</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  onPopup: PropTypes.func,
};

export default withStyles(styles)(Footer);
