import React from 'react';
// import Hidden from '@mui/material/Hidden';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

// import Burger from '@mui/icons-material/Menu';
import B01Season from './blocks/B01Season';
import B02Gallery from './blocks/B02Gallery';
import B04Claim from './blocks/B04Claim';
import B05Team from './blocks/B05Team';

import Header from './components/Header';
import Footer from './components/Footer';

import styles from './styles';
import B03Alumni from './blocks/B03Alumni';
import B00Intro from './blocks/B00Intro';

const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupsState: {},
      // mobileNav: false,
    };
    this.onPopup = this.onPopup.bind(this);
  }

  onPopup(id, state) {
    this.setState(prev => ({ popupsState: { ...prev.popupsState, [id]: state } }));
  }

  render() {
    const { popupsState } = this.state;
    const { classes } = this.props;
    return (
      <div>
        {/* <Hidden lgUp>
          <Burger className={`${classes.burger} ${mobileNav ? classes.menuOpen : ''}`} color="primary" />
        </Hidden> */}
        <div className={classes.bgWhite}>
          <Header popupsState={popupsState} onPopup={this.onPopup} />
          <B00Intro baseUrl={BASE_URL} />
          <B01Season baseUrl={BASE_URL} />
          <B02Gallery baseUrl={BASE_URL} />
          <B03Alumni baseUrl={BASE_URL} />
          <B04Claim baseUrl={BASE_URL} />
          <B05Team baseUrl={BASE_URL} />
          <Footer popupsState={popupsState} onPopup={this.onPopup} />
        </div>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
