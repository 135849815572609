import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Container, Typography } from '@mui/material';
import Anchor from '../../components/Anchor';
import parse from '../../utils/parse';

import styles from './styles';
import data from '../../utils/data';

class B04Claim extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b04claim;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <div className={classes.headerContainer}>
        <Typography variant="h1" className={classes.title}>
          {parse(this.dataBlock.title)}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {parse(this.dataBlock.description)}
        </Typography>
        <Typography variant="subtitle1" className={classes.subdescription}>
          {parse(this.dataBlock.subdescription)}
        </Typography>
        <Button
          sx={{ fontSize: '1em' }}
          variant="contained"
          className={classes.buttonLink}
          href="https://www.campusfrance.org/fr/rendez-vous-en-france"
          target="_blank"
        >
          {this.dataBlock.buttonText}
        </Button>
      </div>
    );
  }

  renderMainPicture() {
    const { classes } = this.props;
    return (
      <div className={classes.mainPictureContainer}>
        <img alt="" className={classes.mainPictureElement} src={`${this.imgBase}${this.dataBlock.img1}`} />
      </div>
    );
  }

  renderTextImageOne() {
    const { classes } = this.props;
    return (
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.kitFlex}>
          <img alt="" className={classes.kitPicture} src={`${this.imgBase}${this.dataBlock.img2}`} />
          <Typography variant="h1" className={classes.kitText}>
            {parse(this.dataBlock.kit)}
          </Typography>
        </div>
      </Container>
    );
  }

  renderTextImageTwo() {
    const { classes } = this.props;
    return (
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.kitFlex}>
          <div className={classes.printTextContainer}>
            {this.dataBlock.prints.map((text, idx) => (
              <Typography key={idx} variant="body1" className={classes.printTextElement}>
                {parse(text)}
              </Typography>
            ))}
          </div>
          <img alt="" className={classes.kitPicture} src={`${this.imgBase}${this.dataBlock.img3}`} />
        </div>
      </Container>
    );
  }

  renderContent() {
    const { classes } = this.props;
    return (
      <>
        <Container maxWidth="xl" className={classes.container}>
          {this.renderHeader()}
          {this.renderMainPicture()}
        </Container>
        <div className={classes.kitContainer}>{this.renderTextImageOne()}</div>
        <div className={classes.posterContainer}>{this.renderTextImageTwo()}</div>
      </>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.blockContainer}>
        <Anchor id="claim" />
        {this.renderContent()}
      </div>
    );
  }
}

B04Claim.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B04Claim);
