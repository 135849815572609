import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';

import styles from './styles';
import DeviceInfos from '../../utils/DeviceInfos';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.stepBack = this.stepBack.bind(this);
    this.stepNext = this.stepNext.bind(this);
    this.state = { step: 0, arrow: 'right' };
  }

  onSelect(item) {
    this.setState(prev => ({ step: item, arrow: item >= prev.step ? 'right' : 'left' }));
  }

  stepBack() {
    const { sliderData } = this.props;
    this.setState(prev => ({ step: prev.step - 1 < 0 ? sliderData.length - 1 : prev.step - 1, arrow: 'left' }));
  }

  stepNext() {
    const { sliderData } = this.props;
    this.setState(prev => ({ step: prev.step + 1 >= sliderData.length ? 0 : prev.step + 1, arrow: 'right' }));
  }

  renderArrows() {
    const { classes, color } = this.props;
    const arrowsColor = color ? 'arrowswhite' : `arrowsblue`;
    return (
      <div className={classes.arrows}>
        <IconButton className={`${classes.arrow} ${classes[arrowsColor]} ${classes.leftArrow}`} onClick={this.stepBack}>
          <ArrowBackIcon />
        </IconButton>
        <IconButton
          className={`${classes.arrow} ${classes[arrowsColor]} ${classes.rightArrow}`}
          onClick={this.stepNext}
        >
          <ArrowForwardIcon />
        </IconButton>
      </div>
    );
  }

  renderPoints() {
    const { classes, sliderData, color } = this.props;
    const pointsColor = color ? 'pointswhite' : `pointsblue`;
    const activePointsColor = color ? 'activePointswhite' : `activePointsblue`;
    return (
      <div className={classes.points}>
        {sliderData.map((item, idx) => (
          <div
            className={`${classes.point} ${
              idx === this.state.step ? classes[activePointsColor] : classes[pointsColor]
            }`}
            key={idx}
            onClick={() => this.onSelect(idx)}
          ></div>
        ))}
      </div>
    );
  }

  renderImage() {
    const { sliderData, imgPath, imgFormat, classes } = this.props;
    const { step, arrow } = this.state;
    const path = imgPath || 'sliders';
    const format = imgFormat || '';
    const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;
    return (
      <div className={classes.imgInner}>
        {sliderData.map((item, idx) => {
          let direction = '';
          let timeout = { enter: 900, exit: 300 };
          if (arrow === 'right') {
            direction = idx === step ? 'right' : 'left';
          } else {
            direction = idx === step ? 'left' : 'right';
          }
          if (DeviceInfos.device.type === 'desktop') {
            timeout = { enter: 900, exit: 600 };
            if (arrow === 'right') {
              direction = idx === step ? 'right' : 'up';
            } else {
              direction = idx === step ? 'up' : 'right';
            }
          }
          return (
            <div key={idx} className={`${classes.imgItem} ${idx === step ? classes.itemActive : ''}`}>
              <Slide in={idx === step} direction={direction} timeout={timeout}>
                <div className={`${classes.image}`}>
                  <img
                    key="img"
                    src={`${BASE_URL}/${path}/${item.img}`}
                    alt=""
                    className={`${classes.image} ${format === 'imgContain' ? classes.imgContain : ''}`}
                  />
                </div>
              </Slide>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Container className={`${classes.container} slider-container`}>
        <div className={`${classes.slideInfo} slider-info`}>
          {this.renderArrows()}
          {this.renderImage()}
          {this.renderPoints()}
        </div>
      </Container>
    );
  }
}

Slider.propTypes = {
  sliderData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  imgPath: PropTypes.string,
  imgFormat: PropTypes.string,
  color: PropTypes.string,
};

Slider.default = {
  hasTitles: false,
};

export default withStyles(styles)(Slider);
