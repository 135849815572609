export default theme => ({
  container: {
    padding: '0 !important',
    maxWidth: 'none !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  vimeoWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
  },
  itemActive: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(./images/play-filled-white.svg)',
    backgroundSize: '64px 64px',
    cursor: 'pointer',
  },
  vimeoItem: {
    position: 'relative',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      '& > iframe': {
        width: '100% !important',
      },
    },
  },
});
