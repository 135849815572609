export default theme => ({
  blockContainer: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  container: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      position: 'relative',
      paddingTop: '0',
      marginTop: '0',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  title: {
    color: theme.palette.blue.main,
    fontSize: '4.3rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  description: {
    color: theme.palette.blue.main,
    fontSize: '2.2rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  subdescription: {
    color: theme.palette.blue.main,
    fontSize: '1.25rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem !important',
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  mainPictureContainer: {
    position: 'relative',
    marginTop: theme.spacing(8),
  },
  mainPictureElement: {
    width: '100%',
    objectFit: 'cover',
  },
  buttonLink: {
    margin: 'auto !important',
    width: 'fit-content !important',
    backgroundColor: `${theme.palette.red.main} !important`,
    borderRadius: '2rem !important',
    height: 'fit-content',
  },
  kitContainer: {
    backgroundColor: theme.palette.blue.main,
    marginTop: '-500px',
    paddingTop: '380px',
    [theme.breakpoints.down('md')]: {
      marginTop: '0',
      paddingTop: '0',
    },
  },
  kitFlex: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: '5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      gap: '2rem',
    },
  },
  kitPicture: {
    width: '55%',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  kitText: {
    width: '45%',
    color: theme.palette.white.main,
    textTransform: 'uppercase',
    fontSize: '4.3rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '2rem',
      paddingLeft: theme.spacing(2),
    },
  },
  printTextContainer: {
    width: '45%',
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down('lg')]: {
      alignSelf: 'flex-end',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  },
  printTextElement: {
    color: theme.palette.white.main,
    display: 'block',
    position: 'relative',
    fontSize: '1.7rem',
    margin: '0 0 8px 40px !important',
    '&::before': {
      content: "'→'",
      position: 'absolute',
      top: 0,
      left: '-40px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.2rem !important',
    },
  },
  posterContainer: {
    backgroundColor: theme.palette.blue.main,
    marginTop: '-100px',
    paddingBottom: '100px',
    [theme.breakpoints.down('md')]: {
      marginTop: '0',
    },
  },
});
