import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Player from '@vimeo/player';
import styles from './styles';

class VimeoPlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vimeoId: VimeoPlay.getUniqueId(),
      isReady: false,
    };

    this.onReady = this.onReady.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.nodeRef = React.createRef();
  }

  componentDidMount() {
    this.initPlayer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPlayed !== this.props.isPlayed && prevState.isReady) {
      if (this.props.isPlayed) {
        this.play();
      } else {
        this.pause();
      }
    }
  }

  componentWillUnmount() {
    if (this.nodeRef?.current) {
      this.nodeRef.current.innerHTML = '';
    }
  }

  initPlayer() {
    const { vimeoUrl, muted, isPlayed } = this.props;
    const { vimeoId } = this.state;
    this.player = new Player(vimeoId, {
      url: vimeoUrl,
      width: 300,
      autoplay: isPlayed,
      controls: false,
      muted,
      loop: true,
      quality: '1024p',
      title: false,
      byline: false,
      background: isPlayed,
    });
    this.player.ready().then(this.onReady);
  }

  onReady() {
    this.setState({ isReady: true });
  }

  play() {
    const { isReady } = this.state;
    const { isPlayed } = this.props;
    if (isReady && isPlayed) {
      this.player.play();
    }
  }

  pause() {
    const { isReady } = this.state;
    const { isPlayed } = this.props;
    if (isReady && !isPlayed) {
      this.player.pause();
    }
  }

  handleClick() {
    const { isReady } = this.state;
    const { handleIsPlayed, position } = this.props;
    if (isReady) {
      handleIsPlayed(position);
    }
  }

  render() {
    const { classes, isPlayed } = this.props;
    const { vimeoId } = this.state;
    return (
      <Box sx={{ position: 'relative' }}>
        <Box onClick={this.handleClick} className={`${classes.vimeoWrapper} ${isPlayed ? '' : classes.itemActive}`} />
        <Box ref={this.nodeRef} className={classes.vimeoItem} id={vimeoId}></Box>
      </Box>
    );
  }
}

VimeoPlay.propTypes = {
  classes: PropTypes.object.isRequired,
  vimeoUrl: PropTypes.string.isRequired,
  isPlayed: PropTypes.bool,
  handleIsPlayed: PropTypes.func,
  position: PropTypes.number,
  muted: PropTypes.bool,
};

VimeoPlay.defaultProps = {
  isPlayed: false,
  muted: false,
};

VimeoPlay.lastIncr = 0;

VimeoPlay.getUniqueId = () => {
  VimeoPlay.lastIncr++;
  return `vimeo-player-${VimeoPlay.lastIncr}`;
};

export default withStyles(styles)(VimeoPlay);
